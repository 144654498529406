import styled from "styled-components";
import { Heading } from "@pepdirect/v3/ui/Heading";
import { fontWeights } from "@pepdirect/v3/types";
import { Device } from "@pepdirect/v3/styles/screenSizes";
import { colors } from "@pepdirect/v3/styles/variables";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Styled404 = styled.div`
  width: calc(100vw - 160px);
  max-width: 1200px;
  margin: 70px 0 120px;

  @media ${Device.mobile} {
    width: calc(100vw - 40px);
  }
`;

export const StyledHeadingH1 = styled(Heading)`
  color: ${colors.gray900};
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const StyledText404 = styled.div`
  color: ${colors.gray900};
`;

export const StyledHeadingH2 = styled(Heading)`
  color: ${colors.gray900};
  margin-bottom: 32px;
  font-weight: ${fontWeights["400"]};
`;

export const StyledLinks = styled.div`
  display: flex;
  gap: 30px;
`;
