import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import { LocalizationContext } from "v3/context/localization";
import { InternalLink } from "@pepdirect/v3/ui/InternalLink";
import {
  Styled404,
  StyledHeadingH1,
  StyledHeadingH2,
  StyledLinks,
  StyledText404,
  StyledWrapper,
} from "./index.styled";

interface Custom404Props {
  fallbackUrl: string;
}

export const Custom404: FC<Custom404Props> = ({ fallbackUrl }) => {
  const theme = useContext(ThemeContext);
  const { localization } = useContext(LocalizationContext);

  const {
    headingText = "Sorry, that page can't be found",
    subHeadingText = "It could be a typo, it could be missing, or the link to your cart has changed.",
    homeButtonText = "Return to the home page",
    cartButtonText = "View your cart",
  } = localization?.components?.customNotFound || {};

  return (
    <StyledWrapper>
      <Styled404>
        <StyledHeadingH1 level="h1" size="xl">
          <div>{headingText}</div>
          <StyledText404>404</StyledText404>
        </StyledHeadingH1>
        <StyledHeadingH2 level="h2" size="m">
          {subHeadingText}
        </StyledHeadingH2>
        <StyledLinks>
          <InternalLink href={`${fallbackUrl}`} color={theme.color.primary}>
            {homeButtonText}
          </InternalLink>
          <InternalLink
            href={`${fallbackUrl}/cart`}
            color={theme.color.primary}
          >
            {cartButtonText}
          </InternalLink>
        </StyledLinks>
      </Styled404>
    </StyledWrapper>
  );
};
