import React, { useContext } from "react";
import { Custom404 } from "v3/components/Custom404";
import { TenantContext } from "v3/context/tenant";

export default function Custom404Page(): JSX.Element {
  const { tenant } = useContext(TenantContext);
  const fallbackUrl = tenant?.fallbackUrl || "";

  return <Custom404 fallbackUrl={fallbackUrl} />;
}
